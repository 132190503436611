<template>
  <div>
    <div class="head">邮箱设置</div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="新邮箱">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="当前密码">
        <el-input
          v-model="form.loginPassword"
          @blur="checkPersonCode"
          show-password
        ></el-input>
        <el-link type="primary" :underline="false">
          <router-link to="change_code"> 忘记密码</router-link>
        </el-link>
      </el-form-item>
      <el-form-item label="图片验证码">
        <el-input
          id="code"
          class="code"
          v-model="code"
          maxlength="14"
        ></el-input>
        <div class="login-code" @click="refreshCode">
          <!--验证码组件-->
          <el-tooltip
            effect="light"
            :hide-after="1100"
            content="看不清?点击切换"
            placement="right"
          >
            <s-identify :identifyCode="identifyCode"></s-identify>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item label="邮箱验证码">
        <el-input v-model="form.emailc" maxlength="14"></el-input>
        <el-button type="primary" @click="getVerifyCode" v-show="messageCode"
          >获取邮箱验证码</el-button
        >
        <el-button type="info" v-show="!messageCode"
          >(<span>{{ count_down }}</span
          >s)后重新发送</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancle()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SIdentify from "@/components/sidentify";
import { QUERY, UPDATE, DELETE, INSERT } from "@/services/dao.js";
import axios from "axios";
export default {
  components: { SIdentify },
  data() {
    return {
      form: {
        id: "",
        email: "",
        loginPassword: "",
        emailc: "",
      },
      identifyCodes: "1234567890",
      identifyCode: "",
      code: "", //text框输入的验证码
      messageCode: true,
      count_down: 60,
      first_email: "", // 接收验证码的邮箱
      flag: Boolean, // 当前密码正确为true
      flag_1: false, // 短信验证码正确则为true
    };
  },
  created() {
    this.init();
    this.refreshCode();
  },

  methods: {
    async init() {
      let info = await QUERY(
        "post",
        "",
        'PCUsers(where: {id: {_eq: "' +
          this.$store.state.userinfo.id +
          '"}}) {id      email  }'
      );
      this.form = info.data.PCUsers[0];
    },
    // 获取邮箱验证码倒计时
    getMessageCode() {
      // console.log("点击")
      this.messageCode = false;
      let time = 59;
      let t = setInterval(() => {
        this.count_down = time;
        time--;
        if (time < 0) {
          this.count_down = 60;
          clearInterval(t);
          this.messageCode = true;
        }
      }, 1000);
    },
    // 获取邮箱验证码
    async getVerifyCode() {
      // 验证是否为合法
      let reg_email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/g;
      if (!reg_email.test(this.form.email)) {
        this.$message.warning("请填写正确的邮箱!");
        return;
      }
      // 查询该邮箱是否被注册
      // 根据用户输入的邮箱 查找数据库 该邮箱是否未被注册
      let search = await QUERY(
        "post",
        "",
        'PCUsers(where:{email:{_eq:"' + this.form.email + '"}}) {id}'
      );
      // console.log("search_affected_rows_loginName",search);
      // console.log("查找数据库 该邮箱是否未被注册: ",search.data.PCUsers.length>0)
      if (search.data.PCUsers.length > 0) {
        this.$message.warning("该邮箱已被注册!");
        return;
      }
      // 验证通过则发送邮箱验证码
      this.getMessageCode(); // 验证通过则进入倒计时
      // 请求后台发送邮箱验证码
      await axios
        .post(
          this.api.LoginURL.concat("/user/registerByEmail"),
          {
            email: this.form.email,
            loginName: this.$store.state.userinfo.name,
          },
          {
            headers: {
              // 'Access-Control-Allow-Origin':'*',  //解决cors头问题
              // 'Access-Control-Allow-Credentials':'true', //解决session问题
              "Content-Type": "application/json", //将表单数据传递转化为form-data类型
            },
          }
        )
        .then((res) => {
          // console.log("拿到后台邮箱验证码cb: ",res);
          // 给 message_code赋值
          if (res.data.success) {
            // 发送验证码成功才存入邮箱
            this.first_email = this.form.email;
          } else {
            this.$message.warning(res.data.respMsg);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkPersonCode() {
      // console.log(this.$store.state.userinfo)
      // console.log("this.this.form.loginPassword",this.form.loginPassword)
      if (this.form.loginPassword === undefined) {
        this.$message.warning("当前密码不能为空!");
        return;
      }
      let info = await QUERY(
        "post",
        "",
        'PCUsers(where: {id: {_eq: "' +
          this.$store.state.userinfo.id +
          '"},loginPassword: {_eq: "' +
          this.$md5(this.form.loginPassword) +
          '"}}) {id      name  }'
      );
      // console.log(info.data.PCUsers.length)
      if (info.data.PCUsers.length !== 1) {
        this.$message({
          message: "当前密码错误!",
          type: "error",
          duration: "1000",
        });
        this.flag = false;
        return false;
      } else {
        this.flag = true;
        return true;
      }
    },
    async onSubmit() {
      // console.log(this.form.field);
      // console.log(typeof this.form.loginPassword);
      // 验证密码是否正确
      if (this.form.loginPassword === undefined) {
        this.$message.warning("请输入当前密码!");
        return;
      }
      if (!this.flag) {
        this.$message.warning("当前密码错误!");
        return;
      }

      if (this.code == "") {
        this.$message({
          message: "请输入图形验证码",
          type: "warning",
          duration: "1000",
        });
        return;
      }
      if (this.identifyCode !== this.code) {
        this.code = "";
        this.identifyCode = "";
        this.$message({
          message: "请输入正确的图形验证码",
          type: "warning",
          duration: "1000",
        });
        return;
      }

      // 验证邮箱验证码的正确性
      //
      if (this.form.email == "" || this.form.emailc == "") {
        this.$message.warning("请完善信息!");
        return;
      }
      // console.log("register->email");
      await axios
        .post(this.api.LoginURL.concat("/user/register"), {
          email: this.form.email,

          vcode: this.form.emailc,
        })
        .then((res) => {
          // console.log("用户填的验证码发到后台cb: ",res);
          if (!res.data.success) {
            this.$message.warning(res.data.respMsg);
            // console.log("66666",!res.data.success)
            return;
          }
          this.flag_1 = res.data.success;
        })
        .catch((err) => {
          console.log(err);
        });
      // 接收到邮箱验证码后是否更改邮箱地址
      if (this.first_email != this.form.email) {
        this.$message.warning("请勿更改邮箱地址,请重新获取验证码!");
        return;
      }
      // 如果邮箱与短信验证码匹配
      if (this.flag_1) {
        let data = await UPDATE(
          "POST",
          "",
          'update_User(_set:{email: "' +
            this.form.email +
            '"}, where: {id: {_eq:' +
            this.$store.state.userinfo.id +
            "}}) {  affected_rows }"
        );
        if (data.data.update_User.affected_rows > 0) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: "1000",
          });
          this.$router.push({ name: "Basicinformation" });
        } else {
          this.$message({
            message: "修改失败",
            type: "warning",
            duration: "1000",
          });
        }
      }
    },
    handleChange(value) {
      console.log(value);
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    // 取消返回上一级
    cancle() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
};
</script>
<style scoped>
.head {
  border-bottom: 1px solid #b5b5b5;
  line-height: 50px;
  margin-bottom: 40px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 800;
}
.label {
  margin-left: -50px;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 50%;
  margin-right: 10px;
}
.code {
  width: 124px;
  height: 31px;
  float: left;
  /* border: 1px solid rgba(186, 186, 186, 1); */
}
.login-code {
  cursor: pointer;
  width: 51%;
}
</style>
